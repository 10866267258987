import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_nsdow4h45mluimo6wwwhfrkexa/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_nsdow4h45mluimo6wwwhfrkexa/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_nsdow4h45mluimo6wwwhfrkexa/node_modules/next/font/google/target.css?{\"path\":\"src/common/styles/variants/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_nsdow4h45mluimo6wwwhfrkexa/node_modules/next/font/google/target.css?{\"path\":\"src/common/styles/variants/fonts.ts\",\"import\":\"Frank_Ruhl_Libre\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--frank-ruhl-libre\",\"display\":\"swap\"}],\"variableName\":\"frankRuhlLibre\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/analytics-events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/providers.tsx");
