"use client";

import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type PopupProps = {
  id: string;
  content: ReactNode;
};

type PopupContextType = {
  onOpenPopup: (content: ReactNode) => string;
  onClosePopup: (id?: string) => void;
  onCloseAllPopup: () => void;
};

const PopupContext = createContext({} as PopupContextType);

export default function PopupProvider({ children }: { children: React.ReactNode }) {
  const [popups, setPopups] = useState<PopupProps[]>([]);

  const onOpenPopup = useCallback((content: ReactNode) => {
    const id = Math.random().toString(36).substring(7);
    setPopups(prev => [...prev, { id, content }]);
    return id;
  }, []);

  const onClosePopup = useCallback((id?: string) => {
    setPopups(prev => {
      const popupId = id || prev[prev.length - 1]?.id;
      return prev.filter(popup => popup.id !== popupId);
    });
  }, []);

  const onCloseAllPopup = useCallback(() => {
    setPopups([]);
  }, []);

  useEffect(() => {
    return () => {
      setPopups([]);
    };
  }, []);

  const contextValue = useMemo(
    () => ({ onOpenPopup, onClosePopup, onCloseAllPopup }),
    [onOpenPopup, onClosePopup, onCloseAllPopup]
  );

  return (
    <PopupContext.Provider value={contextValue}>
      {popups.map(popup => (
        <div key={popup.id}>{popup.content}</div>
      ))}
      {children}
    </PopupContext.Provider>
  );
}

export const usePopup = () => useContext<PopupContextType>(PopupContext);
