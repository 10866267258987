import posthog from "posthog-js";

export const initiatePostHog = () => {
  if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    // Enable debug mode before initialization
    if (process.env.NODE_ENV === "development") {
      posthog.debug();
    }

    // checks that we are client-side
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      opt_in_site_apps: true,
      capture_pageleave: true,
    });
  }
};
